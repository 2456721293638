import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import AppLayoutTemplate from "../frames/MainLayout"
import BlankHero from "../components/HeroBlank"
import { StyledDash } from "../components/Dash"
import {
  device,
  RGtheme,
  moduleSpacing,
  spacers,
} from "../components/utils/designsystem";
import ViewportLabel from "../components/ViewportLabel"
import InlineText from "../components/InlineText"


import { ApplyLayoutFrame as SnapToPageGrid } from "../frames/ResponsiveModuleContainer"

const Bio = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${moduleSpacing.mobileS};

  font-size: ${RGtheme.typeStyles.mBioText.fontsize};
  line-height: ${RGtheme.typeStyles.mBioText.lineHeight};
  @media ${device.tablet} {
    margin-bottom: ${moduleSpacing.tablet};
  }
  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBioText.fontsize};
    line-height: ${RGtheme.typeStyles.dBioText.lineHeight};
    margin-bottom: ${moduleSpacing.laptop};
  }
  
  @media ${device.desktop} {
    margin-bottom: ${moduleSpacing.desktop};
  }

  & > section > p {
    padding-bottom: ${spacers["16"].rem};
  }

  @media ${device.laptop} {
    & > section > p {
      padding-bottom: ${spacers["32"].rem};
    }
  }
`


function getBioHTML(text) {
  return { __html: "" + text }
}

const AboutPage = ({ data }) => (
  <AppLayoutTemplate>
    <SnapToPageGrid>
      <BlankHero />
      <Bio
        dangerouslySetInnerHTML={getBioHTML(data.dataJson.About.bio_text)}
      ></Bio>
      <StyledDash />

      
    <InlineText header="Currently—">{data.dataJson.About.availability_text}</InlineText>
    
    </SnapToPageGrid>

    <ViewportLabel left>Profile</ViewportLabel>
    <ViewportLabel right>Profile</ViewportLabel>
  </AppLayoutTemplate>
)

export const getAboutPageData = graphql`
  query AboutPageQuery {
    dataJson {
      About {
        bio_text
        availability_text
        contact_email
        clients {
          name
          displayName
          category
        }
      }
    }
  }
`
export default AboutPage
